export default function getMemberType(member) {
  if (member != null) {
    if (
      !Object.keys(member).includes('consumer_id') &&
      !Object.keys(member).includes('provider_id') &&
      Object.keys(member).includes('organization_id')
    ) {
      return 'organization';
    }

    if (
      Object.keys(member).includes('provider_id') &&
      Object.keys(member).includes('organization_id')
    ) {
      return 'provider';
    }

    if (
      Object.keys(member).includes('consumer_id') &&
      Object.keys(member).includes('organization_id')
    ) {
      return 'consumer';
    }
  }

  return null;
}
