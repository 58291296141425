/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import {
  Box,
  Button,
  DefaultTheme,
  Heading,
  makeStyles
} from 'groundkeeper-component-library';
import { Drawer as MuiDrawer } from '@mui/material';

const useStyles = makeStyles((theme: DefaultTheme) => ({
  drawer: {
    fontFamily: theme.font.primary,
    '& .MuiDrawer-paperAnchorRight': {
      background: theme.color.background.default,
      width: '35rem',
      [theme.mediaQueries.tiny]: {
        width: '90vw'
      }
    }
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  }
}));

export default function Drawer({
  open,
  onClose,
  children
}: InferProps<typeof Drawer.propTypes>) {
  const classes = useStyles();

  return (
    <MuiDrawer
      className={classes.drawer}
      anchor="right"
      open={open}
      onClose={() => onClose()}
      ModalProps={{
        disableScrollLock: true
      }}
    >
      {/* @ts-ignore: no-typescript-component */}
      <Button
        className={classes.closeButton}
        size="large"
        onClick={() => onClose()}
        icon
        lib="fa"
      >
        close
      </Button>
      {children}
    </MuiDrawer>
  );
}

Drawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node
};

Drawer.defaultProps = {};
